import {
  Box,
  Button,
  createStyles,
  Grid,
  Group,
  Image,
  Skeleton,
  useMantineTheme
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useSWRConfig } from 'swr'

import { api, notificationHandler, Yup } from '@/lib/utils'
import { useRouterLoading } from '@/providers/RouterLoadingProvider'

import * as ProfileFormFields from './Fields'

interface Props {
  userData: any
  isSitePrivate: boolean
}

interface FormValues {
  firstName: string
  lastName: string
  password?: string
  confirmPassword?: string
  oldPassword?: string
}

const useStyles = createStyles(() => ({
  pictureBox: {
    border: '1px solid #CCCCCC',
    height: '190px',
    margin: 'auto',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '190px'
  }
}))

export default function Profile({ userData, isSitePrivate = false }: Props) {
  // Hooks
  const router = useRouter()
  const { isLoading: isLoadingGlobal } = useRouterLoading()

  const { mutate: mutateGlobal } = useSWRConfig()

  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const { classes } = useStyles()

  // States
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [picture, setPicture] = useState<string>(userData.picture || '')

  // Constants
  const { site: siteSlug } = router.query || {}
  const initialValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    password: '',
    confirmPassword: '',
    oldPassword: ''
  }

  const schema = Yup.object().shape({
    firstName: Yup.string().min(2).max(50).required(),
    lastName: Yup.string().min(2).max(150).required(),
    password: Yup.string(),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
    oldPassword: Yup.string().when('password', {
      is: (password: string) => password.length > 0,
      then: Yup.string().required('Senha antiga obrigatória')
    })
  })

  // Mantine form
  const form = useForm({
    initialValues,
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true
  })

  // Actions
  const errorHandler = (error: AxiosError) => {
    const { response } = error
    const errorData = response?.data || ({} as any)
    const errorKey = Object.keys(errorData)[0]
    return notificationHandler({
      variant: 'error',
      message: errorData[errorKey] || 'Houve um erro ao tentar atualizar os dados'
    })
  }

  const patchProfile = (data: any, successMessage: string) => {
    api
      .patch(`/${siteSlug}/accounts/me/`, { user: data })
      .then(response => {
        mutateGlobal(`/${siteSlug}/accounts/me/`)
        notificationHandler({ variant: 'success', message: successMessage })
        return response?.data?.uid
      })
      .catch(errorHandler)
      .finally(() => setIsSubmitting(false))
  }

  const handleFileUpload = (payload: File[]) => {
    if (payload?.length > 1) return null

    const filteredFiles = payload.find(
      (file, index, array) =>
        file?.size < 5242880 && index === array.findIndex(fItem => fItem.name === file.name)
    )

    if (filteredFiles) {
      const reader = new FileReader()
      reader.onloadend = () => {
        patchProfile({ picture: reader.result }, 'Foto atualizada com sucesso')
      }
      reader.readAsDataURL(filteredFiles)
      setPicture(filteredFiles ? URL.createObjectURL(filteredFiles) : '')
    }
  }

  const handleSubmit = async (newValues: FormValues) => {
    setIsSubmitting(true)

    const newProfile = {
      first_name: newValues.firstName,
      last_name: newValues.lastName
    }

    if (form.isDirty()) {
      patchProfile(newProfile, 'Dados atualizados com sucesso')
    }

    if (newValues.password) {
      api
        .post(`/${siteSlug}/password/`, {
          oldPassword: newValues.oldPassword,
          newPassword: newValues.password
        })
        .then(() => {
          notificationHandler({ variant: 'success', message: 'Senha atualizada com sucesso' })
          router.reload()
        })
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col span={12} xs={4} sm={3} md={2}>
          <Box mb="xl" className={classes.pictureBox}>
            {picture ? (
              <Image alt="Foto" src={picture} />
            ) : (
              <Skeleton height={190} animate={false} />
            )}
          </Box>
          <ProfileFormFields.Picture inputProps={{ onChange: handleFileUpload }} />
        </Grid.Col>
        <Grid.Col span={12} xs={8} sm={9} md={10}>
          <Grid>
            <Grid.Col span={12} md={6}>
              <ProfileFormFields.FirstName
                inputProps={{
                  ...form.getInputProps('firstName'),
                  disabled: isSubmitting || isSitePrivate
                }}
              />
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <ProfileFormFields.LastName
                inputProps={{
                  ...form.getInputProps('lastName'),
                  disabled: isSubmitting || isSitePrivate
                }}
              />
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <ProfileFormFields.Password
                inputProps={{
                  ...form.getInputProps('password'),
                  disabled: isSubmitting
                }}
              />
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <ProfileFormFields.ConfirmPassword
                inputProps={{
                  ...form.getInputProps('confirmPassword'),
                  disabled: isSubmitting
                }}
              />
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <ProfileFormFields.OldPassword
                inputProps={{
                  ...form.getInputProps('oldPassword'),
                  disabled: isSubmitting
                }}
              />
            </Grid.Col>
          </Grid>
          <Group position="right" mt="xl">
            <Button
              type="submit"
              size={isXs ? 'sm' : 'md'}
              fullWidth={!!isXs}
              disabled={!form.isValid() || !form.isDirty() || isLoadingGlobal}
              loading={isSubmitting}>
              Salvar
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  )
}
