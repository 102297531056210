import { ActionIcon, Group, Loader, Radio, Text, TextInput } from '@mantine/core'
import { IconAddressBook, IconBriefcase, IconHome, IconSearch } from '@tabler/icons'
import { AxiosError, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { api, capitalize } from '@/lib/utils'

interface Props {
  inputProps: any
  callback?: (data: AxiosResponse | undefined, status: number | undefined) => void
}

export const Label = ({ inputProps }: Props) => {
  const [isOtherLabel, setIsOtherLabel] = useState(false)
  const { value, disabled } = inputProps || {}

  useEffect(() => {
    setIsOtherLabel(value !== 'Casa' && value !== 'Trabalho')
  }, [value])

  return (
    <Radio.Group
      label="Identificador do endereço"
      {...inputProps}
      inputWrapperOrder={['label', 'error', 'input']}>
      <Radio
        value="Casa"
        disabled={disabled}
        label={
          <Group position="left" spacing="xs">
            <IconHome size={20} />
            <Text>Casa</Text>
          </Group>
        }
      />
      <Radio
        value="Trabalho"
        disabled={disabled}
        label={
          <Group position="left" spacing="xs">
            <IconBriefcase size={20} />
            <Text>Trabalho</Text>
          </Group>
        }
      />
      <Radio
        value={isOtherLabel ? value : ''}
        disabled={disabled}
        checked={isOtherLabel}
        label={
          <Group position="left" spacing="xs">
            <IconAddressBook size={20} />
            <Text>Outro</Text>
            <TextInput
              {...inputProps}
              placeholder="Qual?"
              type="text"
              size="sm"
              error={Boolean(inputProps.error)}
              hidden={!isOtherLabel}
              onChange={event => inputProps.onChange(capitalize(event.currentTarget.value))}
            />
          </Group>
        }
      />
    </Radio.Group>
  )
}

export const RecipientName = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Destinatário" placeholder="Destinatário" type="text" />
)

export const PhoneNumber = ({ inputProps }: Props) => (
  <TextInput
    {...inputProps}
    label="Telefone de contato"
    placeholder="Telefone de contato"
    type="text"
  />
)

export const Postcode = ({ inputProps, callback }: Props) => {
  // Hooks
  const router = useRouter()
  const { site: siteSlug } = router.query || {}

  // States
  const [searching, setSearching] = useState(false)

  const handlePostalCode = () => {
    if (inputProps.value.length > 6) {
      setSearching(true)
      api
        .get(`/${siteSlug}/postal-code/BR/${inputProps.value}/`)
        .then((response: AxiosResponse) => callback?.(response, response.status))
        .catch((error: AxiosError) => callback?.(error.response, error.response?.status))
        .finally(() => setSearching(false))
    }
  }

  return (
    <TextInput
      {...inputProps}
      label="CEP"
      placeholder="CEP"
      type="text"
      rightSection={
        searching ? (
          <Loader size="xs" />
        ) : (
          <ActionIcon variant="subtle" size="md" color="custom">
            <IconSearch size={18} />
          </ActionIcon>
        )
      }
      disabled={searching || inputProps.disabled}
      onBlur={handlePostalCode}
      onKeyPress={ev => {
        if (ev.key === 'Enter') {
          ev.preventDefault()
          handlePostalCode()
        }
      }}
    />
  )
}

export const Line1 = ({ inputProps }: Props) => (
  <TextInput
    {...inputProps}
    label="Endereço (rua, avenida, via, etc)"
    placeholder="Rua, avenida, via, etc"
    type="text"
  />
)
export const Line2 = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Bairro" placeholder="Bairro" type="text" />
)

export const Line3 = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Complemento (opcional)" placeholder="Complemento" type="text" />
)

export const Line4 = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Número" placeholder="Número" type="text" />
)
