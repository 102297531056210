import { Button, FileInput, TextInput } from '@mantine/core'
import { IconUpload } from '@tabler/icons'
import { AxiosResponse } from 'axios'

const acceptTypes = ['image/gif', 'image/png', 'image/jpeg', 'image/webp', 'image/bmp']

interface Props {
  inputProps: any
  callback?: (data: AxiosResponse | undefined, status: number | undefined) => void
}

export const FirstName = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Primeiro nome" placeholder="Primeiro nome" type="text" />
)

export const LastName = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Sobrenome" placeholder="Sobrenome" type="text" />
)

export const Password = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Senha" placeholder="Senha" type="password" />
)

export const ConfirmPassword = ({ inputProps }: Props) => (
  <TextInput
    {...inputProps}
    label="Confirmar Senha"
    placeholder="Confirmar Senha"
    type="password"
  />
)

export const OldPassword = ({ inputProps }: Props) => (
  <TextInput {...inputProps} label="Senha antiga" placeholder="Senha antiga" type="password" />
)

export const Picture = ({ inputProps }: Props) => (
  <Button variant="outline" leftIcon={<IconUpload />} component="label" fullWidth>
    Alterar foto
    <FileInput
      {...inputProps}
      placeholder="Foto"
      label="Foto"
      multiple
      accept={acceptTypes.join(',')}
      sx={{ display: 'none' }}
    />
  </Button>
)
