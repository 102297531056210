import { Button, Grid, Group } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useRouter } from 'next/router'
import { FormEvent, useState } from 'react'
import { useSWRConfig } from 'swr'

import { api, errorHandler, getUrlString, notificationHandler, Yup } from '@/lib/utils'
import { BankAccount } from '@/types'

import * as BankAccountFields from './Fields'

interface Props {
  onSelect?: (bankAccount: BankAccount) => void
  handleCloseDrawer?: () => void
}

interface FormValues {
  holder: string
  taxDocument: string
  bank: string
  agencyNumber: string
  agencyCheckNumber: string
  accountNumber: string
  accountCheckNumber: string
  accountType: string
}

export default function Order({ onSelect, handleCloseDrawer }: Props) {
  // Hooks
  const router = useRouter()
  const { mutate: mutateGlobal } = useSWRConfig()

  // Constants
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)

  // States
  const [bank, setBank] = useState(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  // Mantine Form
  const initialValues: FormValues = {
    holder: '',
    taxDocument: '',
    bank: '',
    agencyNumber: '',
    agencyCheckNumber: '',
    accountNumber: '',
    accountCheckNumber: '',
    accountType: 'checking'
  }

  const taxDocumentRegex =
    /^(\d{3}\.?\d{3}\.?\d{3}-?\d{2})$|^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/

  const schema = Yup.object().shape({
    holder: Yup.string()
      .matches(
        /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ])+$/u,
        'Digite apenas letras'
      )
      .required('Você precisa informar o nome conforme está cadastrado em sua conta no banco')
      .min(3)
      .max(65),
    taxDocument: Yup.string()
      .matches(taxDocumentRegex, 'CPF/CNPJ inválido')
      .required('Insira um CPF ou CNPJ válido'),
    bank: Yup.string().required('É preciso selecionar qual o banco em que você possui conta'),
    agencyNumber: Yup.string().required('Você precisa informar a agência de sua conta no banco'),
    agencyCheckNumber: Yup.string(),
    accountNumber: Yup.string().required('Precisamos do número de sua conta no banco'),
    accountCheckNumber: Yup.string(),
    accountType: Yup.string().required('Selecione o tipo da sua conta no banco')
  })

  const form = useForm({
    validate: yupResolver(schema),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues
  })

  // Actions
  const handleSubmit = async () => {
    setSubmitting(true)

    try {
      await api.post(`/${siteSlug}/bank-accounts/`, form.values).then((res: any) => {
        notificationHandler({
          variant: 'success',
          message: 'Sua nova conta bancária foi criada com sucesso'
        })

        if (res?.data) {
          form.reset()
          setBank(null)
        }

        onSelect?.(res?.data)
        handleCloseDrawer?.()

        setSubmitting(false)
        mutateGlobal(`/${siteSlug}/bank-accounts/`)
      })
    } catch (error: any) {
      setSubmitting(false)
      const erros = errorHandler(error) || {}
      notificationHandler({
        variant: 'error',
        message: erros?.messages?.[0] || 'Houve um erro durante o cadastro da conta bancária'
      })
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid gutter={2}>
        <Grid.Col xs={12}>
          <BankAccountFields.AccountTypeField
            inputProps={{ ...form.getInputProps('accountType') }}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <BankAccountFields.HolderField
            inputProps={{
              ...form.getInputProps('holder'),
              onBlur: ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
                form.setFieldValue('holder', value.replace(/[^\S\r\n]+/g, ' ').trim())
              }
            }}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <BankAccountFields.TaxDocumentField
            inputProps={{ ...form.getInputProps('taxDocument') }}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <BankAccountFields.CommonBankSearchField
            inputProps={{
              selected: bank,
              defaultValue: '',
              defaultSearchValue: '',
              onSelect: (choice: any) => {
                setBank(choice)
                form.setTouched({ bank: true })
                form.setFieldValue('bank', choice.code)
              },
              ...form.getInputProps('bank')
            }}
          />
        </Grid.Col>
        <Grid.Col xs={8}>
          <BankAccountFields.AgencyNumberField
            inputProps={{ ...form.getInputProps('agencyNumber') }}
          />
        </Grid.Col>
        <Grid.Col xs={4}>
          <BankAccountFields.AgencyCheckNumberField
            inputProps={{
              value: form.values.agencyCheckNumber,
              onChange: (event: any) => form.setFieldValue('agencyCheckNumber', event.target.value)
            }}
          />
        </Grid.Col>
        <Grid.Col xs={8}>
          <BankAccountFields.AccountNumberField
            inputProps={{ ...form.getInputProps('accountNumber') }}
          />
        </Grid.Col>
        <Grid.Col xs={4}>
          <BankAccountFields.AccountCheckNumberField
            inputProps={{
              value: form.values.accountCheckNumber,
              onChange: (event: any) => form.setFieldValue('accountCheckNumber', event.target.value)
            }}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <Group position="right" mt="sm">
            <Button
              loading={submitting}
              onClick={handleSubmit}
              disabled={!form.isValid() || !form.isDirty()}>
              Salvar
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  )
}
