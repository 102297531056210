import 'dayjs/locale/pt-br'

import { Alert, Grid, Input, Paper, Text } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons'
import React, { useState } from 'react'

import { CityAutocomplete } from '@/containers/autocompletes'
import { Postcode as PostcodeField } from '@/containers/forms/AddressForm/Fields'
import { MaskedPostcode } from '@/lib/utils/masks'

interface AddressProps {
  config: object
  description?: string
  isRequired: boolean
  uid?: string
  value: any
  name: string
  showLabel?: boolean
  onChange: (value: any) => void
  inputProps: any
}

export default function AddressField({
  config,
  isRequired,
  description,
  name,
  showLabel,
  value,
  onChange,
  inputProps
}: AddressProps) {
  // States
  const [isSubmitting] = useState(false)
  const [disableFields, setDisableFields] = useState({ line1: true, line2: true, city: true })
  const [postcodeError, setPostcodeError] = useState<any>(null)

  // Actions
  const postcodeCallback = (response: any, status: number | undefined) => {
    setPostcodeError(status !== 200 ? response?.data || 'error' : null)
    if (status === 200) {
      const { city, cityUid, country, countryCode, line1, line2, state, stateCode } =
        response?.data || {}
      onChange({
        ...value,
        line1: line1 || '',
        line2: line2 || '',
        line3: '',
        line4: '',
        cityUid,
        city,
        state,
        stateCode,
        country,
        countryCode
      })
      setDisableFields({ line1: !!line1, line2: !!line2, city: !!cityUid })
    } else {
      onChange({
        ...value,
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        city: '',
        cityUid: '',
        state: '',
        stateCode: '',
        country: '',
        countryCode: ''
      })
      setDisableFields({ line1: true, line2: true, city: true })
    }

    return null
  }

  return (
    <Paper withBorder p={15}>
      {showLabel && <Text fw="bold">{name}</Text>}
      <Grid>
        {!!description && <Grid.Col xs={12}>{description}</Grid.Col>}
        <Grid.Col span={12} hidden={!postcodeError}>
          <Alert title="CEP não localizado" icon={<IconAlertCircle size={16} />} color="red">
            <Text>
              Não localizamos o CEP <strong>{value?.postcode}</strong> em nossa base de endereços.
            </Text>
          </Alert>
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <PostcodeField
            inputProps={{
              required: isRequired,
              value: value?.postcode || '',
              component: MaskedPostcode,
              onChange: (e: any) => onChange({ ...value, postcode: e.target.value }),
              onInput: () => setPostcodeError(null),
              disabled: isSubmitting || inputProps.disabled,
              ...config
            }}
            callback={postcodeCallback}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <CityAutocomplete
            selected={
              value?.cityUid
                ? {
                    uid: value?.cityUid,
                    code: '',
                    name: value.city,
                    state: {
                      uid: '',
                      code: value?.stateCode,
                      name: value?.state,
                      country: { uid: '', code: value?.countryCode, name: value?.country }
                    }
                  }
                : null
            }
            onChange={newValue => {
              if (newValue) {
                const { uid, name, state } = newValue
                onChange({
                  ...value,
                  city: name,
                  cityUid: uid,
                  state: state?.name,
                  stateCode: state?.code,
                  country: state?.country?.name,
                  countryCode: state?.country?.code
                })
              } else {
                onChange({
                  ...value,
                  city: '',
                  cityUid: '',
                  state: '',
                  stateCode: '',
                  country: '',
                  countryCode: ''
                })
              }
            }}
            inputProps={{
              placeholder: 'Cidade',
              disabled: disableFields.city || isSubmitting || inputProps.disabled
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={7}>
          <Input.Wrapper
            withAsterisk={isRequired}
            label={showLabel ? 'Logradouro' : ''}
            inputWrapperOrder={['label', 'input', 'description', 'error']}>
            <Input
              type="text"
              placeholder="Logradouro"
              required={isRequired}
              value={value?.line1 || ''}
              disabled={disableFields.line1 || isSubmitting || inputProps.disabled}
              onChange={(e: any) => onChange({ ...value, line1: e.target.value })}
              {...config}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={12} sm={5}>
          <Input.Wrapper
            withAsterisk={isRequired}
            label={showLabel ? 'Número' : ''}
            inputWrapperOrder={['label', 'input', 'description', 'error']}>
            <Input
              type="text"
              placeholder="Número"
              required={isRequired}
              value={value?.line4 || ''}
              disabled={isSubmitting || inputProps.disabled}
              onChange={(e: any) => onChange({ ...value, line4: e.target.value })}
              {...config}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <Input.Wrapper
            withAsterisk={isRequired}
            label={showLabel ? 'Bairro' : ''}
            inputWrapperOrder={['label', 'input', 'description', 'error']}>
            <Input
              type="text"
              placeholder="Bairro"
              required={isRequired}
              value={value?.line2 || ''}
              disabled={disableFields.line2 || isSubmitting || inputProps.disabled}
              onChange={(e: any) => onChange({ ...value, line2: e.target.value })}
              {...config}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <Input.Wrapper
            label={showLabel ? 'Complemento' : ''}
            inputWrapperOrder={['label', 'input', 'description', 'error']}>
            <Input
              type="text"
              placeholder="Complemento"
              value={value?.line3 || ''}
              disabled={isSubmitting || inputProps.disabled}
              {...config}
              onChange={(e: any) => onChange({ ...value, line3: e.target.value })}
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Paper>
  )
}
