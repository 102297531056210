import 'dayjs/locale/pt-br'

import {
  Box,
  Button,
  createStyles,
  Divider,
  FileButton,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Paper,
  Skeleton,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { randomId, useMediaQuery } from '@mantine/hooks'
import { IconReplace, IconUpload, IconX } from '@tabler/icons'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { KeyedMutator } from 'swr'

import { MaskedTextInput } from '@/components/commons'
import { StudentChannelAutocomplete, StudentShowcaseAutocomplete } from '@/containers/autocompletes'
import { useFetch, useStudent } from '@/lib/hooks'
import { getShowcasesFromGrades, notificationHandler, Yup } from '@/lib/utils'
import { MaskedTaxDocumentCPF } from '@/lib/utils/masks'
import { useNav } from '@/providers/NavProvider'
import {
  Channel as ChannelType,
  Showcase as ShowcaseType,
  StudentSiteProfile as StudentSiteProfileType
} from '@/types'

import * as api from './api'

interface Props {
  student?: StudentSiteProfileType | null
  mutate?: KeyedMutator<any>
  fallbackAction?: (student: StudentSiteProfileType) => void
}

type Enrollment = {
  channel?: ChannelType | null
  showcase?: ShowcaseType | null
  key: string
}
interface FormValues {
  picture: File | string | null
  firstName: string
  lastName: string
  enrollments: Enrollment[]
  taxDocument: string
  birthdate: string | null
}

const errorNotification = (message: string) => {
  notificationHandler({ variant: 'error', title: 'Ops algo deu errado.', message })
}

const successNotification = (message: string) => {
  notificationHandler({ variant: 'success', title: 'Sucesso!', message })
}

const useStyles = createStyles(theme => ({
  pictureBox: {
    position: 'relative',
    border: '1px solid #CCCCCC',
    borderRadius: theme.radius.sm,
    height: 190,
    width: 190,
    maxHeight: 190,
    maxWidth: 190,
    margin: 'auto'
  },
  pictureBoxError: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.colors.red[6],
    padding: theme.spacing.xs
  },
  errorBorder: { borderColor: theme.colors.red[6] }
}))

export default function StudentsForm({ student, mutate, fallbackAction }: Props) {
  // Hooks
  const router = useRouter()
  const { classes } = useStyles()
  const { mutate: mutateStudent } = useStudent()
  const theme = useMantineTheme()

  const { studentModalOpenFromBuyButton } = useNav()

  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)

  // Constants
  const {
    site: siteSlug,
    channel: channelSlug,
    store: storeSlug,
    showcase: showcaseSlug
  } = router?.query || {}
  const currentChannelStudentChannelProfile = student?.studentChannelProfiles?.find(
    studentChannelProfile => studentChannelProfile?.channelProfile?.channel?.slug === channelSlug
  )

  const taxDocumentRegex = /^(\d{3}\.?\d{3}\.?\d{3}-?\d{2})/

  const resetRef = useRef<() => void>(null)
  const dateRef = useRef<HTMLInputElement | null>(null)
  const today = new Date()
  const minDate = new Date('1950-01-01').toISOString()
  const maxDate = today.toISOString()
  const pictureMaxSize = 2_621_440

  // Fetch
  const { data: optionsData, error: optionsDataError } = useFetch([
    siteSlug ? `/${siteSlug}/students/options/` : null
  ])

  // Constants
  const isOptionsDataLoading = !optionsData && !optionsDataError
  const { fields = [] } = optionsData || {}
  const isTaxDocumentRequired = fields?.includes('tax_document')
  const isBirthdateRequired = fields?.includes('birthdate')

  // Validation schema
  const enrollmentSchema = Yup.object().shape({
    channel: Yup.object().nullable().required(),
    showcase: Yup.object().nullable().required(),
    key: Yup.string()
  })

  const schema = Yup.object().shape({
    picture: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'file-size',
        'O arquivo não pode ser maior que 2.5MB',
        value => !value || typeof value === 'string' || (value && value?.size <= pictureMaxSize)
      ),
    firstName: Yup.string().min(2).max(50).required(),
    lastName: Yup.string().min(2).max(150).required(),
    ...(isTaxDocumentRequired
      ? { taxDocument: Yup.string().matches(taxDocumentRegex, 'Digite um CPF válido').required() }
      : {}),
    ...(isBirthdateRequired
      ? {
          birthdate: Yup.date()
            .nullable()
            .required()
            .min(minDate, 'Data deve ser maior que 01/01/1950')
            .max(maxDate, 'Data deve ser menor que hoje')
            .typeError('Data inválida')
        }
      : {}),
    enrollments: Yup.array().of(enrollmentSchema)
  })

  const initialEnrollments = () => {
    // update student during channel scope
    if (!!student && channelSlug) {
      return [
        {
          channel: currentChannelStudentChannelProfile?.channelProfile?.channel || null,
          showcase: currentChannelStudentChannelProfile?.showcase || null,
          key: randomId()
        }
      ]
    }
    // update student during site scope
    if (!!student && !channelSlug) {
      return student?.studentChannelProfiles?.map(studentChannelProfile => ({
        channel: studentChannelProfile?.channelProfile?.channel || null,
        showcase: studentChannelProfile?.showcase,
        key: randomId()
      }))
    }
    // create or update student without enrollment
    return [{ channel: null, showcase: null, key: randomId() }]
  }

  // Form
  const form = useForm<FormValues>({
    initialValues: {
      picture: student?.picture || null,
      firstName: student?.firstName || '',
      lastName: student?.lastName || '',
      taxDocument: student?.taxDocument || '',
      birthdate: student?.birthdate || null,
      enrollments: initialEnrollments()
    },
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true
  })

  // Constants
  const isDisabled = !form.isDirty() || !form.isValid()

  // Fetch
  const { data: channelData } = useFetch([
    siteSlug && channelSlug ? `/${siteSlug}/channels/${channelSlug}/` : null
  ])
  const { data: gradesData } = useFetch([
    siteSlug && channelSlug && showcaseSlug ? `/${siteSlug}/channels/${channelSlug}/grades/` : null
  ])
  const { data: showcaseData } = useFetch([
    siteSlug && channelSlug && storeSlug && showcaseSlug
      ? `/${siteSlug}/channels/${channelSlug}/stores/${storeSlug}/showcases/${showcaseSlug}/`
      : null
  ])

  // States
  const [isLoading, setIsLoading] = useState(false)

  // Actions
  const clearFile = () => {
    form.setFieldValue('picture', null)
    resetRef.current?.()
  }

  const handleCreate = async () => {
    try {
      const { enrollments, ...newValues } = form.values || {}
      const { channel, showcase } = enrollments?.[0] || {}
      const response = await api.signup({
        site: siteSlug as string,
        studentData: { ...newValues, channel, showcase }
      })
      if (response.status === 201) {
        successNotification('Aluno cadastrado com sucesso!')
        mutateStudent()
        form.reset()
        if (mutate) mutate()
        if (fallbackAction) fallbackAction(response?.data)
      }
    } catch (error) {
      const { response } = error as AxiosError
      errorNotification(
        Object.values(response?.data || {}).join('<br />') ||
          'Ocorreu um erro ao cadastrar o aluno. Tente novamente mais tarde.'
      )
    }
  }

  const handleUpdate = async () => {
    if (!student?.uid) return null
    const { enrollments = [], ...newValues } = form.values || {}
    Promise.all([
      // workaround bugfix
      // api.update({ site: siteSlug as string, studentUid: student.uid, studentData: newValues }),
      ...enrollments?.map(enrollment =>
        api
          .update({
            site: siteSlug as string,
            studentUid: student.uid,
            studentData: {
              ...newValues,
              channel: enrollment?.channel,
              showcase: enrollment?.showcase
            }
          })
          .catch(error => error?.response)
      )
    ])
      .then(requests => {
        const failedRequests = requests.filter(request => ![200, 201].includes(request.status))
        if (failedRequests.length) {
          errorNotification(
            Object.values(failedRequests[0]?.data || {}).join('<br />') ||
              'Ocorreu um erro ao atualizar o aluno. Tente novamente mais tarde.'
          )
        } else {
          successNotification('Aluno editado com sucesso!')
          if (mutate) mutate()
          if (fallbackAction) fallbackAction(student)
        }
      })
      .catch(error => {
        const { response } = error as AxiosError
        errorNotification(
          Object.values(response?.data || {}).join('<br />') ||
            'Ocorreu um erro ao atualizar o aluno. Tente novamente mais tarde.'
        )
      })
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    if (student) {
      await handleUpdate()
    } else {
      await handleCreate()
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  // Effects
  useEffect(() => {
    if (channelSlug && channelData) {
      form.setFieldValue('enrollments.0.channel', channelData || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelSlug, channelData])

  useEffect(() => {
    if (channelSlug && showcaseSlug && showcaseData && !!studentModalOpenFromBuyButton) {
      const showcases = getShowcasesFromGrades(gradesData)
      form.setFieldValue(
        'enrollments.0.showcase',
        showcases?.find((showcase: ShowcaseType) => showcase?.uid === showcaseData?.uid) || null
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showcaseSlug, showcaseData, gradesData])

  const enrollmentFields = form.values.enrollments.map((enrollment, index) => (
    <Paper p="md" withBorder key={enrollment.key}>
      <Stack>
        <StudentChannelAutocomplete
          selected={form.values.enrollments?.[index]?.channel}
          onChange={newValue => {
            if (!newValue) form.setFieldValue(`enrollments.${index}.showcase`, null)
            form.setFieldValue(`enrollments.${index}.channel`, newValue || null)
          }}
          inputProps={{
            ...form.getInputProps(`enrollments.${index}.channel`),
            onInput: () => form.clearFieldError(`enrollments.${index}.channel`),
            disabled: isLoading || !!channelSlug || !!student
          }}
        />
        {!!form.values.enrollments?.[index]?.channel && (
          <StudentShowcaseAutocomplete
            selected={form.values.enrollments?.[index]?.showcase}
            loading={
              isLoading || (!!showcaseSlug && !!studentModalOpenFromBuyButton && !showcaseData)
            }
            onChange={newValue =>
              form.setFieldValue(`enrollments.${index}.showcase`, newValue || null)
            }
            channel={form.values.enrollments?.[index]?.channel?.slug}
            inputProps={{
              ...form.getInputProps(`enrollments.${index}.showcase`),
              onInput: () => form.clearFieldError(`enrollments.${index}.showcase`),
              disabled: isLoading || (!!showcaseSlug && !!studentModalOpenFromBuyButton)
            }}
          />
        )}
      </Stack>
    </Paper>
  ))

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <LoadingOverlay visible={isOptionsDataLoading} />
      <Grid>
        <Grid.Col span={12} md="content">
          <Stack spacing="xs">
            <Box
              className={`${classes.pictureBox} ${form.errors.picture ? classes.errorBorder : ''}`}>
              {form.values?.picture ? (
                <Image
                  height={188}
                  width={188}
                  fit="contain"
                  alt="Foto"
                  src={
                    typeof form.values.picture === 'string'
                      ? form.values.picture
                      : URL.createObjectURL(form.values.picture)
                  }
                />
              ) : (
                <Skeleton height={188} animate={false} radius={0} />
              )}
              <Box className={classes.pictureBoxError} hidden={!form.errors.picture}>
                <Text color={theme.white} align="center" size="xs">
                  {form.errors.picture}
                </Text>
              </Box>
            </Box>
            <FileButton
              {...form.getInputProps('picture')}
              resetRef={resetRef}
              accept="image/gif, image/png, image/jpeg, image/webp, image/bmp">
              {props => (
                <Button
                  {...props}
                  variant="outline"
                  size="xs"
                  leftIcon={
                    form.values?.picture ? <IconReplace size={16} /> : <IconUpload size={16} />
                  }
                  fullWidth>
                  {form.values?.picture ? 'Alterar' : 'Adicionar'} foto
                </Button>
              )}
            </FileButton>
            <Button
              color="dark"
              variant="white"
              size="xs"
              compact
              disabled={!form.values?.picture}
              hidden={!form.values?.picture}
              onClick={clearFile}
              leftIcon={<IconX size={16} />}
              fullWidth>
              Remover foto
            </Button>
          </Stack>
        </Grid.Col>
        <Grid.Col span={12} md="auto">
          <Stack>
            <TextInput
              required
              placeholder="Primeiro nome"
              disabled={isLoading}
              {...form.getInputProps('firstName')}
            />
            <TextInput
              required
              placeholder="Sobrenome"
              disabled={isLoading}
              {...form.getInputProps('lastName')}
            />
            {isTaxDocumentRequired && (
              <MaskedTextInput
                MaskedComponent={MaskedTaxDocumentCPF}
                required
                placeholder="CPF do aluno"
                type="text"
                disabled={isLoading}
                {...form.getInputProps('taxDocument')}
              />
            )}
            {isBirthdateRequired && (
              <TextInput
                ref={dateRef}
                required
                type={student?.birthdate ? 'date' : 'text'}
                placeholder="Data de nascimento"
                disabled={isLoading}
                {...form.getInputProps('birthdate')}
                onFocus={() => {
                  if (dateRef.current?.type === 'text') dateRef.current.type = 'date'
                }}
              />
            )}
            {form.values.enrollments?.length > 1 && (
              <Divider label={<Title order={isXs ? 6 : isSm ? 5 : 4}>Vitrines</Title>} />
            )}
            {enrollmentFields}
          </Stack>
        </Grid.Col>
      </Grid>
      <Group position="right" mt="xl">
        <Button type="submit" loading={isLoading} disabled={isDisabled}>
          {student ? 'Editar' : 'Criar'} aluno
        </Button>
      </Group>
    </form>
  )
}
