import { TextInput } from '@mantine/core'
import { AxiosResponse } from 'axios'

interface Props {
  inputProps: any
  callback?: (data: AxiosResponse | undefined, status: number | undefined) => void
}

export const FirstName = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="Primeiro nome" type="text" />
)

export const LastName = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="Sobrenome" type="text" />
)

export const Email = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="E-mail" type="email" />
)

export const Password = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="Senha" type="password" />
)

export const ConfirmPassword = ({ inputProps }: Props) => (
  <TextInput {...inputProps} placeholder="Confirmar Senha" type="password" />
)
