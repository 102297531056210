import Compressor from 'compressorjs'

const IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/png']

const removeExtension = (fileName: string) =>
  fileName.substring(0, fileName.lastIndexOf('.')) || fileName

export async function compress(
  file: File,
  quality: number = 0.6,
  maxHeight: number = 1080,
  maxWidth: number = 1920
): Promise<File> {
  if (!IMAGE_TYPES.includes(file.type)) return file
  return await new Promise((resolve, reject) => {
    return new Compressor(file, {
      quality,
      maxHeight,
      maxWidth,
      success: (compressedFile: any) => {
        if (compressedFile instanceof File) return resolve(compressedFile)
        else {
          const compressedFileFromBlob = new File([compressedFile], removeExtension(file.name), {
            type: compressedFile.type
          })
          return resolve(compressedFileFromBlob)
        }
      },
      error: reject
    })
  })
}
