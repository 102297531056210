import { Text } from '@mantine/core'
import {
  IconArchive,
  IconCircleCheck,
  IconCircleX,
  IconClock,
  IconCoin,
  IconCopy,
  IconCurrencyDollar,
  IconCurrencyDollarOff,
  IconExclamationMark,
  IconFolderPlus,
  IconHandStop,
  IconPlayerPlay,
  IconReceipt,
  IconRotateClockwise2,
  IconSend,
  IconTruck,
  IconX
} from '@tabler/icons'

import { OrderType } from '@/types'

import Base from './Base'

const STATUS_TYPES = {
  analysis: 'analysis',
  created: 'created',
  order_created: 'order-created',
  payment_pending: 'payment-pending',
  payment_approved: 'payment-approved',
  payment_denied: 'payment-denied',
  handling: 'handling',
  invoice: 'invoice',
  invoiced: 'invoiced',
  delivered: 'delivered',
  waiting: 'waiting',
  queued: 'queued',
  cancellation_requested: 'cancellation-requested',
  cancel: 'cancel',
  cancelled: 'cancelled',
  reversed: 'reversed',
  refunded: 'refunded',
  partially_refunded: 'partially-refunded',
  pending: 'pending',
  active: 'active',
  authorized: 'authorized',
  finished: 'finished',
  transferred: 'transferred',
  error: 'error',
  not_authorized: 'not-authorized'
}

export const ORDER_STATUS = {
  analysis: {
    status: STATUS_TYPES.analysis,
    color: 'orange',
    icon: <IconCircleCheck />,
    label: 'Analisando',
    recommended: false
  },
  authorized: {
    status: STATUS_TYPES.order_created,
    color: 'green',
    icon: <IconCircleCheck />,
    label: 'Autorizado',
    recommended: false
  },
  created: {
    status: STATUS_TYPES.created,
    color: 'blue',
    icon: <IconFolderPlus />,
    label: 'Pedido Criado',
    recommended: false
  },
  'order-created': {
    status: STATUS_TYPES.order_created,
    color: 'blue',
    icon: <IconFolderPlus />,
    label: 'Pedido Criado',
    recommended: false
  },
  'payment-pending': {
    status: STATUS_TYPES.payment_pending,
    color: 'orange',
    icon: <IconClock />,
    label: 'Pagamento Pendente',
    recommended: false
  },
  'payment-approved': {
    status: STATUS_TYPES.payment_approved,
    color: 'green',
    icon: <IconCurrencyDollar />,
    label: 'Pagamento Aprovado',
    recommended: true
  },
  'payment-denied': {
    status: STATUS_TYPES.payment_denied,
    color: 'red',
    icon: <IconCurrencyDollarOff />,
    label: 'Pedido Negado',
    recommended: true
  },
  handling: {
    status: STATUS_TYPES.handling,
    color: 'yellow',
    icon: <IconHandStop />,
    label: 'Em Manuseio',
    recommended: true
  },
  invoice: {
    status: STATUS_TYPES.invoice,
    color: 'blue',
    icon: <IconCopy />,
    label: 'Faturando',
    recommended: false
  },
  invoiced: {
    status: STATUS_TYPES.invoiced,
    color: 'green',
    icon: <IconReceipt />,
    label: 'Faturado',
    recommended: false
  },
  delivered: {
    status: STATUS_TYPES.delivered,
    color: 'green',
    icon: <IconTruck />,
    label: 'Entregue',
    recommended: false
  },
  waiting: {
    status: STATUS_TYPES.waiting,
    color: 'orange',
    icon: <IconClock />,
    label: 'Aguardando pagamento',
    recommended: false
  },
  queued: {
    status: STATUS_TYPES.waiting,
    color: 'orange',
    icon: <IconClock />,
    label: 'Na fila',
    recommended: false
  },
  'cancellation-requested': {
    status: STATUS_TYPES.cancellation_requested,
    color: 'orange',
    icon: <IconExclamationMark />,
    label: 'Cancelamento solicitado',
    recommended: true
  },
  cancel: {
    status: STATUS_TYPES.cancel,
    color: 'blue',
    icon: <IconX />,
    label: 'Cancelando',
    recommended: false
  },
  canceled: {
    status: STATUS_TYPES.cancelled,
    color: 'red',
    icon: <IconCircleX />,
    label: 'Cancelado',
    recommended: false
  },
  cancelled: {
    status: STATUS_TYPES.cancelled,
    color: 'red',
    icon: <IconCircleX />,
    label: 'Cancelado',
    recommended: false
  },
  reversed: {
    status: STATUS_TYPES.reversed,
    color: 'red',
    icon: <IconRotateClockwise2 />,
    label: 'Revertido',
    recommended: false
  },
  refunded: {
    status: STATUS_TYPES.refunded,
    color: 'red',
    icon: <IconRotateClockwise2 />,
    label: 'Reembolsado',
    recommended: false
  },
  'partially-refunded': {
    status: STATUS_TYPES.partially_refunded,
    color: 'red',
    icon: <IconRotateClockwise2 />,
    label: 'Parcialmente Reembolsado',
    recommended: false
  },
  'not-authorized': {
    status: STATUS_TYPES.not_authorized,
    color: 'red',
    icon: <IconX />,
    label: 'Não autorizado',
    recommended: true
  },
  error: {
    status: STATUS_TYPES.error,
    color: 'red',
    icon: <IconX />,
    label: 'Erro',
    recommended: false
  }
}

export const CONTRACT_STATUS = {
  pending: {
    status: STATUS_TYPES.pending,
    color: 'orange',
    icon: <IconClock />,
    label: 'Pendente'
  },
  'payment-approved': {
    status: STATUS_TYPES.payment_approved,
    color: 'green',
    icon: <IconCoin />,
    label: 'Pagamento Aprovado',
    recommended: true
  },
  'payment-denied': {
    status: STATUS_TYPES.payment_denied,
    color: 'red',
    icon: <IconCurrencyDollarOff />,
    label: 'Pedido Negado',
    recommended: true
  },
  invoice: {
    status: STATUS_TYPES.invoice,
    color: 'blue',
    icon: <IconCopy />,
    label: 'Faturando',
    recommended: false
  },
  invoiced: {
    status: STATUS_TYPES.invoiced,
    color: 'green',
    icon: <IconReceipt />,
    label: 'Faturado',
    recommended: false
  },
  active: {
    status: STATUS_TYPES.active,
    color: 'green',
    icon: <IconPlayerPlay />,
    label: 'Ativo'
  },
  'payment-pending': {
    status: STATUS_TYPES.payment_pending,
    color: 'orange',
    icon: <IconClock />,
    label: 'Pagamento Pendente',
    recommended: false
  },
  'cancellation-requested': {
    status: STATUS_TYPES.cancellation_requested,
    color: 'orange.9',
    icon: <IconExclamationMark />,
    label: 'Cancelamento de contrato solicitado'
  },
  canceled: {
    status: STATUS_TYPES.cancelled,
    color: 'red',
    icon: <IconCircleX />,
    label: 'Cancelado'
  },
  cancelled: {
    status: STATUS_TYPES.cancelled,
    color: 'red',
    icon: <IconCircleX />,
    label: 'Cancelado'
  },
  finished: {
    status: STATUS_TYPES.finished,
    color: 'green',
    icon: <IconCircleCheck />,
    label: 'Finalizado'
  }
}

export const TUITION_STATUS = {
  pending: {
    status: STATUS_TYPES.pending,
    color: 'orange',
    icon: <IconClock />,
    label: 'Pendente'
  },
  'payment-approved': {
    status: STATUS_TYPES.payment_approved,
    color: 'green',
    icon: <IconCoin />,
    label: 'Pagamento Aprovado',
    recommended: true
  },
  'payment-pending': {
    status: STATUS_TYPES.payment_pending,
    color: 'orange',
    icon: <IconClock />,
    label: 'Pagamento Pendente',
    recommended: false
  },
  'payment-denied': {
    status: STATUS_TYPES.payment_denied,
    color: 'red',
    icon: <IconCurrencyDollarOff />,
    label: 'Pedido Negado',
    recommended: true
  },
  active: {
    status: STATUS_TYPES.active,
    color: 'green',
    icon: <IconPlayerPlay />,
    label: 'Ativo'
  },
  invoice: {
    status: STATUS_TYPES.invoice,
    color: 'blue',
    icon: <IconCopy />,
    label: 'Faturando',
    recommended: false
  },
  invoiced: {
    status: STATUS_TYPES.invoiced,
    color: 'green',
    icon: <IconReceipt />,
    label: 'Faturado',
    recommended: false
  },
  'cancellation-requested': {
    status: STATUS_TYPES.cancellation_requested,
    color: 'orange.9',
    icon: <IconExclamationMark />,
    label: 'Cancelamento de mensalidade solicitado'
  },
  cancelled: {
    status: STATUS_TYPES.cancelled,
    color: 'red',
    icon: <IconArchive />,
    label: 'Cancelado'
  },
  finished: {
    status: STATUS_TYPES.finished,
    color: 'green',
    icon: <IconCircleCheck />,
    label: 'Finalizado'
  },
  transferred: {
    status: STATUS_TYPES.transferred,
    color: 'yellow',
    icon: <IconSend />,
    label: 'Transferido'
  }
}

type ContractKeyType = keyof typeof CONTRACT_STATUS
type OrderKeyType = keyof typeof ORDER_STATUS
type TuitionKeyType = keyof typeof TUITION_STATUS

interface SingleProps {
  status: OrderKeyType
  statusDisplay: string
  isReverted: boolean
}

function Single({ status, statusDisplay, isReverted, ...otherProps }: SingleProps) {
  if (!status) return null

  return (
    <Base
      id={status + statusDisplay}
      color={ORDER_STATUS[status]?.color}
      icon={ORDER_STATUS[status]?.icon}
      isReverted={isReverted}
      {...otherProps}>
      <Text color={ORDER_STATUS[status]?.color} weight="normal">
        {statusDisplay}
      </Text>
    </Base>
  )
}

interface ContractProps {
  status: ContractKeyType
  statusDisplay: string
  isReverted: boolean
}

function Contract({ status, statusDisplay, isReverted, ...otherProps }: ContractProps) {
  if (!status) return null

  return (
    <Base
      id={status + statusDisplay}
      color={CONTRACT_STATUS[status]?.color}
      icon={CONTRACT_STATUS[status]?.icon}
      isReverted={isReverted}
      {...otherProps}>
      <Text color={CONTRACT_STATUS[status]?.color} weight="normal">
        {statusDisplay}
      </Text>
    </Base>
  )
}

interface TuitionProps {
  status: TuitionKeyType
  statusDisplay: string
  isReverted: boolean
}

function Tuition(props: TuitionProps) {
  const { status, statusDisplay, isReverted, ...otherProps } = props

  if (!status) return null

  return (
    <Base
      id={status + statusDisplay}
      color={TUITION_STATUS[status]?.color}
      icon={TUITION_STATUS[status]?.icon}
      isReverted={isReverted}
      {...otherProps}>
      <Text color={TUITION_STATUS[status]?.color} weight="normal">
        {statusDisplay}
      </Text>
    </Base>
  )
}

interface OrderProps {
  type: OrderType
  variant?: 'standard' | 'filled'
  orderNumber?: string
  isReverted: boolean
  status: string
  statusDisplay: string
}

function Order(props: OrderProps) {
  const {
    type,
    variant = 'filled',
    orderNumber,
    isReverted,
    status,
    statusDisplay,
    ...otherProps
  } = props

  const getCaption = () => {
    if (!orderNumber) return undefined

    switch (type) {
      case 'contract':
        return `Contrato ${orderNumber}`
      case 'tuition':
        return `Mensalidade ${orderNumber}`
      default:
        return `Pedido ${orderNumber}`
    }
  }

  const commonProps = {
    variant,
    caption: getCaption(),
    isReverted,
    statusDisplay,
    ...otherProps
  }

  switch (type) {
    case 'contract':
      return <Contract status={status as ContractKeyType} {...commonProps} />
    case 'tuition':
      return <Tuition status={status as TuitionKeyType} {...commonProps} />
    default:
      return <Single status={status as OrderKeyType} {...commonProps} />
  }
}

export default Order
